import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'i18nPlural'
})
export class InternationalisationPluralPipe implements PipeTransform {
  transform(value: string, count: number): string {
    return value + (count > 1 ? '.PLURAL' : '.SINGULAR');
  }
}
