import { Component, OnInit, Input, ContentChildren, Directive, TemplateRef, QueryList, EventEmitter, AfterContentInit, ContentChild, Output } from '@angular/core';
import { Page } from '@app/core/services/commons/page';
import { Pagination } from '../pagination/pagination.model';

@Directive({
  selector: '[t-bulk-header]'
})
export class BulkHeaderDirective  {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[t-subheader]'
})
export class SubHeaderDirective  {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[t-header]'
})
export class HeaderDirective  {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: '[t-cell]'
})
export class CellDirective  {
  constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
  selector: 'ap-column',
  providers: [{provide: 'TABLE_COLUMN', useExisting: ColumnDirective}],
})
export class ColumnDirective {

  @Input() name = '';

  @ContentChild(HeaderDirective) public header: HeaderDirective;
  @ContentChild(CellDirective) public row: CellDirective;
  @ContentChild(SubHeaderDirective) public subheader: SubHeaderDirective;

  constructor() { }
}

@Component({
  selector: 'ap-table-ng',
  templateUrl: './table-ng.component.html',
  styleUrls: ['./table-ng.component.css']
})
export class TableNgComponent implements OnInit, AfterContentInit {

  @Input()
  get data(): any[] | Page<any> { return this._data; }
  set data(data:  any[] | Page<any>) {
    this._data = data;
    if (data) {
      if ((data as Page<any>).content) {
        const page = (data as Page<any>);
        this.rows = page.content;
        this.pagination = {
          elements: page.size,
          totalElements: page.totalElements,
          page: page.number,
          totalPages: page.totalPages
        };
      } else {
        this.rows = (data as any[]);
      }
    }
  }

  @Input()
  get displayedColumns(): string[] { return this._displayedColumns; }
  set displayedColumns(displayedColumns: string[]) {
    this._displayedColumns = displayedColumns;
    this.refresh();
  }

  constructor() { }

  @Input() dark = false;
  @Input() key: string = null;
  @Input() loading = false;
  @Input() pagination: Pagination;
  @Input() tableDescription: string = '';

  @Output() changePage = new EventEmitter<Number>();
  @Output() changeSize = new EventEmitter<Number>();
  @Output() selectAllRows = new EventEmitter<Boolean>();

  @ContentChildren(ColumnDirective) columns: QueryList<ColumnDirective>;
  @ContentChild(BulkHeaderDirective) bulkHeader: BulkHeaderDirective;

  headerTemplates: TemplateRef<any>[] = [];
  rowTemplates: TemplateRef<any>[] = [];
  subheaderTemplates: TemplateRef<any>[] = [];
  _data: any[] | Page<any>;

  rows: any[] = [];
  _displayedColumns: string[];

  @Input() checked = new Set<string>();

  ngAfterContentInit(): void {
    this.refresh();
  }

  private refresh() {
    if (this.columns) {
      this.headerTemplates = this.columns.filter(c => !this.displayedColumns || this.displayedColumns.includes(c.name)).map(c => c.header.templateRef);
      this.rowTemplates = this.columns.filter(c => !this.displayedColumns || this.displayedColumns.includes(c.name)).map(c => c.row.templateRef);
      this.subheaderTemplates = this.columns.filter(c => !this.displayedColumns || this.displayedColumns.includes(c.name)).map(c => c.subheader ? c.subheader.templateRef : null);
      if (this.subheaderTemplates.every(f => f == null)) {
        this.subheaderTemplates = null;
      }
    }
  }

  ngOnInit(): void { }

  checkAllRows(checkedAll: boolean) {
    this.selectAllRows.emit(checkedAll);
  }

  selectRow(poi: string, checked: boolean) {
    if (checked) {
      this.checked.add(poi);
    } else {
      this.checked.delete(poi);
    }
  }

  onChangePage(page: Number) {
    this.changePage.emit(page);
  }

  onChangeSize(size: Number) {
    this.changeSize.emit(size);
  }
}



