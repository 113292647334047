import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AbstractFilter } from './filter-item/abstract-filter.model';
import { FilterData } from './filterData';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ap-filter',
  templateUrl: './filter.component.html',
  animations: [
    trigger('toggleFilterAction', [
      transition(':enter', [
        style({ height: '0px', overflow: 'hidden' }),
        animate('100ms', style({ height: '55px' })),
      ]),
      transition(':leave', [
        animate('100ms', style({ height: '0px', overflow: 'hidden' }))
      ])
    ]),
  ],
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  @Input() filterData: FilterData;

  /* Export data */
  @Input() exportType: 'SETTLEMENT' | 'DEVICES' | 'ALARMS';

  creationFilterDisplay = false;

  editingFilterId = -1;

  saving = false;
  loading = false;
  readAccessMedia = false;
  isNotUsedSameOperator = false;

  constructor(private toastr: ToastrService, private translateService: TranslateService) { }

  ngOnInit() {
    this.filterData.templates.forEach(template => {
      if (template.getType() ===  'AccessMediaFilterTemplate' && environment.admin.accessMediaReader.enable) {
        this.readAccessMedia = true;
      }
    });
  }

  /**
   * Remove all filter with the keyboard shortcut alt + q
   *
   * @param e KeyboardEvent
   */
  @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.altKey && e.code === 'KeyA') {
      this.filterData.removeFilters();
    }
  }

  onAddFilter(filter: AbstractFilter) {
    const filterAlreadyExists = this.retrieveExistingFilters(filter);
    if (filterAlreadyExists && this.isNotUsedSameOperator) {
      this.displayToastError();
    } else {
      this.removeFilter(this.filterData.filters, filterAlreadyExists);
      this.filterData.addFilter(filter);
    }
    this.creationFilterDisplay = false;
  }

  onEdit(editingFilterId: number) {
    this.editingFilterId = editingFilterId;
  }

  onEditValidate(filter: AbstractFilter) {
    const filterAlreadyExists = this.retrieveExistingFilters(filter);
    if (filterAlreadyExists && this.isNotUsedSameOperator) {
      this.displayToastError();
    } else {
      this.filterData.updateFilter(this.editingFilterId, filter);
    }
    this.editingFilterId = -1;
  }

  onRemove(filter: number) {
    this.creationFilterDisplay = false;
    this.editingFilterId = -1;
    this.filterData.removeFilter(filter);
  }

  onRemoveAll() {
    this.creationFilterDisplay = false;
    this.editingFilterId = -1;
    this.filterData.removeFilters();
  }

  onDisable(index: number) {
    this.filterData.disableFilter(index);
  }

  onSave() {
    this.saving = false;
    this.loading = false;
  }

  closeOverlay() {
    this.loading = false;
  }

  removeFilter<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  retrieveExistingFilters(filter: AbstractFilter) {
    const filterToAddUpdate = filter as any;
    return this.filterData.filters.find((f: any) => {
      this.isNotUsedSameOperator = f?.operator !== filterToAddUpdate?.operator;
      return ((f.filterTemplate.name === filterToAddUpdate.filterTemplate.name && !filterToAddUpdate.filterTemplate.editableByOperator) && ((f?.value?.key === filterToAddUpdate?.value?.key) || (f?.operator !== filterToAddUpdate?.operator)));
    });
  }

  displayToastError() {
    const notifyMsg = this.translateService.instant('FILTERS.LOAD.DELETE_RECORD');
    this.toastr.error(notifyMsg);
  }

}
