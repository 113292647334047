import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map, single } from "rxjs/operators";
import { MaintenanceConfigUI, MaintenanceStateEnum } from "./maintenance-config.model";

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {
  MAINTENANCE_LOCAL_ITEM = "MAINTENANCE_CONFIG";
  MAINTENANCE_ACTIVATED_ERROR = "MAINTENANCE_ACTIVATED_ERROR";

  baseUrl: string = '';
  maintenanceActived: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showBanner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  maintenanceState: BehaviorSubject<MaintenanceStateEnum> = new BehaviorSubject(MaintenanceStateEnum.INITIALIZE)

  constructor (private httpClient: HttpClient) {
    this.init();
  }

  private init() {
    this.baseUrl = environment.admin.api.gateway;
  }

  /**********
   * SUBJECT*
   *********/

  nextMaintenanceActivedSubject(value: boolean) {
    this.maintenanceActived.next(value);
  }

  getMaintenanceActivatedSubject(): boolean {
    return this.maintenanceActived.value;
  }

  nextShowBannerSubject(value: boolean) {
    this.showBanner.next(value);
  }

  getShowBannerSubject(): boolean {
    return this.showBanner.value;
  }

  nextMaintenanceStateSubject(value: MaintenanceStateEnum) {
    this.maintenanceState.next(value);
  }

  getMaintenanceStateSubject(): MaintenanceStateEnum {
    return this.maintenanceState.value;
  }

  /****************
   * HTTP REQUEST *
   ****************/

  getMaintenanceConfig(): Observable<MaintenanceConfigUI> {
    this.init();

    return this.httpClient.get<MaintenanceConfigUI>(`${this.baseUrl}/maintenance`)
    .pipe(
      single(),
      map((_config: MaintenanceConfigUI) => {
        _config.creationDate = new Date();

        if (_config?.maintenance?.banner?.from) {
          _config.maintenance.banner.from = new Date(_config.maintenance.banner.from);
        }
        if (_config?.maintenance?.banner?.to) {
          _config.maintenance.banner.to = new Date(_config.maintenance.banner.to);
        }

        this.storeMaintenanceConfig(_config);
        return _config;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  /************************
   * LOCALSTORAGE REQUEST *
   ************************/

  storeMaintenanceConfig(maintenanceConfig: MaintenanceConfigUI) {
    localStorage.setItem(this.MAINTENANCE_LOCAL_ITEM, JSON.stringify(maintenanceConfig));
  }

  getMaintenanceConfigFromLocal(): MaintenanceConfigUI {
    return JSON.parse(localStorage.getItem(this.MAINTENANCE_LOCAL_ITEM));
  }

  deleteMaintenanceConfigFromLocal() {
    localStorage.removeItem(this.MAINTENANCE_LOCAL_ITEM);
  }
}
