import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

interface Scripts {
    name: string;
    src: string;
}

@Injectable({
    providedIn: 'root'
})

export class ScriptsUtilsService {
    private scripts: any = {};

    ScriptStore: Scripts[] = [
      { name: 'tokenizer', src: `${environment.ogone.url}/hostedtokenization/js/client/tokenizer.min.js`}
    ];

    constructor() {
        // Define an array that list eachScript and if it is loaded or not
        this.ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
      }
      loadScript(name: string) {
        return new Promise((resolve, reject) => {
          // resolve if already loaded
          if (this.scripts[name].loaded) {
            resolve({ script: name, loaded: true, status: 'Already Loaded' });
          } else {
            // load script
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts[name].src;
            script.onload = () => {
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            };
            script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
          }
        });
      }
}
