import { AbstractFilter } from '../filter-item/abstract-filter.model';

export abstract class AbstractFilterTemplate<T extends AbstractFilter> {

  protected static readonly decodePattern: RegExp = /^([^\~]+)\~(0|1)\~([^\~]+)\~?(.*)$/;

  name: string;
  label: string;

  group: string;
  // Optional property to check if the same filter can be edited with different filter operator
  editableByOperator?: boolean = true;

  constructor(name: string, label: string, group: string = 'DEFAULT', editableByOperator?: boolean) {
    this.name = name;
    this.label = label;
    this.group = group;
    if (typeof(editableByOperator) !== 'undefined') {
      this.editableByOperator = editableByOperator;
    }
  }

  public abstract getType(): string;

  public abstract buildDefaultFilter(): T;

  public abstract decode(str: string): T;

  public abstract getDefaultValue(): any;
}
