import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface ConfirmCommentModel {
  title?: string;
  confirmButton?: string;
  closeButton?: string;
}

export interface ResultConfirmCommentModel {
  isConfirmed?: boolean;
  comment?: string;
}

@Component({
  selector: 'ap-confirm-comment-modal',
  templateUrl: './confirm-comment-modal.component.html',
  styleUrls: ['./confirm-comment-modal.component.css']
})
export class ConfirmCommentModalComponent extends SimpleModalComponent<ConfirmCommentModel, ResultConfirmCommentModel>
  implements ConfirmCommentModel, OnInit {
  title: string;
  confirmButton: string;
  closeButton: string;
  confirmCommentForm: FormGroup;

  constructor(private fb:FormBuilder) {
    super();
    this.confirmCommentForm = this.fb.group({
      confirmComment: ['']
    });
  }

  ngOnInit() {
    this.confirmCommentForm = new FormGroup({
      confirmComment: new FormControl('', Validators.required)
    });
  }

  confirm() {
    // modal result object contains two properties {isConfirmed: boolean, comment: string}
    // on click confirm button, we set isConfirmed as true and its comment value
    // then we can get modal result from caller code
    this.result = {isConfirmed: true, comment: this.confirmCommentForm.value.confirmComment};
    this.close();
  }

  cancel() {
    this.result = {isConfirmed: false, comment: ''};
    this.close();
  }

}
