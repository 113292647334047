import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@app/shared/components/sort/sort.model';
import { environment } from '@env/environment';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Page } from '../commons/page';
import { Configuration } from '../configuration';
import { Alarm } from './models/alarm';
import { AlarmFilter } from './models/alarmFilter';
import { AdministratorService } from '@app/core/services/administrator/administrator.service';
import { FilterOperatorEnum } from '../commons/enum/filter-operator.enum';

@Injectable({
  providedIn: 'root'
})
export class AlarmsService {

  private configuration = new Configuration();
  private defaultHeaders = new HttpHeaders();
  private basePath = environment.admin.api.deviceEngine + '/api/alarm-manager';

  constructor(private httpClient: HttpClient, private keycloakService: KeycloakService,
    private administratorService: AdministratorService) {}

  public readAlarms(filter: AlarmFilter, pageNumber: number, pageSize: number, sorts: Sort[] = []): Observable<Page<Alarm>> {
    let params = new HttpParams();
    if ( Number.isInteger(pageNumber) && Number.isInteger(pageSize) ) {
      params = params.set('page', pageNumber.toString()).set('size', pageSize.toString());
    }
    if (filter) {
      params = this.initHttpParamsWithAlarmFilter(filter, params);
    }
    sorts.forEach(sort => {
      params = params.append('sort', sort.field + ',' + sort.order);
    });

    let headers = this.defaultHeaders;
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);

    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Page<Alarm>>(`${this.basePath}/v1/alarms`, {
      headers: headers,
      params
    });
  }

  public getLastAlarm(): Observable<Page<Alarm>> {
    let filter: any;
    filter = Object.assign({}, filter, {
      status: 'OPENED'
    })

    // Add companyReference param when loggedUser is not ADMIN
    const companyReference = this.administratorService.loggedAgent$.getValue().userDetails.companyReference;
    if (companyReference) {
      filter.companyReference = `${FilterOperatorEnum.CONTAINS}:${companyReference}`;
    }

    let params = new HttpParams();
    params = this.initHttpParamsWithAlarmFilter(filter, params);
    params = params
      .set('page', '0')
      .set('size', '1')
      .set('status', 'OPENED')
      .set('sort', 'creationDate,DESC');

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);

    let headers = this.defaultHeaders;
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Page<Alarm>>(`${this.basePath}/v1/alarms`, {
      headers: headers,
      params
    }).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * This endpoint will be used for manual closure
   *
   * @returns alarmId: number
   */
  public close(alarmId: number, closureComment: string) {

    if (!alarmId) {
      throw new Error('Required parameter alarmId was null or undefined when calling close alarm.');
   }

    const headers = this.defaultHeaders
                    .set('actorReference', this.keycloakService.getKeycloakInstance().subject)
                    .set('actorType', this.keycloakService.getKeycloakInstance().realm);

    return this.httpClient.post<Alarm>(`${this.basePath}/v1/alarms/${alarmId}/_close`, closureComment, { headers });
  }

  /**
   * This endpoint is used to find maximum severity of device opened alarms using poi
   *
   * @returns Map<poi, alarmSeverity>
   */
  public getDevicesMaximumSeverity(resourceIds?: Array<string>): Observable<Map<string, string>> {

    return this.httpClient.post<Map<string, string>>(`${this.basePath}/v1/resources/alarms/severity`, resourceIds);
  }

  /**
   * Initialize a given AlarmFiler in a giver HttpParams
   * @param filter AlarmFilter
   * @param params HttpParams
   * @returns HttpParams
   */
  private initHttpParamsWithAlarmFilter(filter: AlarmFilter, params: HttpParams): HttpParams {
    for (const [key, value] of Object.entries(filter)) {
      if (typeof value === 'string' && value.trim() !== '') {
        params = params.set(key, value.trim());
      } else if (value && key.includes('Date')) {
        params = params.set(key, value.toISOString());
      } else {
        params = params.set(key, value);
      }
    }
    return params;
  }
}
