<div class="modal-backdrop">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ title | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="personnal-data">{{ 'PERSONAL_DATA_ALERT.MESSAGE' | translate }}</div>
        <form class="form-horizonal" [formGroup]="confirmCommentForm" autocomplete="off" (ngSubmit)="confirm()">
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ 'ALARM.LIST.ACKNOWLEDGMENT.MOTIF_CLOSURE' | translate }}</label>
            <div class="col-sm-10 form-control-static">
              <textarea [ngClass]="{'error-border': confirmCommentForm.controls['confirmComment'].invalid}" class="form-control" formControlName="confirmComment" maxlength="256"></textarea>
              <div class="remaining">
                <span>{{ confirmCommentForm.controls['confirmComment'].value.length }} /256</span>
              </div>
            </div>
          </div>
        </form>
    </div>
    <div class="modal-footer">
      <button type="submit"
              class="btn btn-success"
              (click)="confirm()"
              attr.aria-label="{{ 'COMMENTS.MODALS.CREATE.YES'| translate }}">{{confirmButton || 'COMMENTS.MODALS.CREATE.YES'| translate }}</button>
      <button type="button"
              class="btn btn-secondary"
              (click)="cancel()"
              attr.aria-label="{{ 'COMMENTS.MODALS.CREATE.CANCEL'| translate }}">{{closeButton || 'COMMENTS.MODALS.CREATE.CANCEL'| translate }}</button>
    </div>
  </div>
</div>
