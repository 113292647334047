import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import merge from 'lodash.merge';
import mergewith from 'lodash.mergewith';
import { TenantService } from '../tenant/tenant.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private httpClient: HttpClient, private translate: TranslateService, private keycloakService: KeycloakService, private tenantService: TenantService, @Inject(DOCUMENT) private document: Document) { }

  fetchConfiguration() {
    return this.httpClient.get<any>(`./assets/config/config.json?cacheBuster=${new Date().getTime()}`);
  }

  fetchRemoteConfiguration(): Observable<any> {
    return this.tenantService.getPropertiesByTenant(environment.admin.api.configServerUri).pipe(tap(config => {
      function overrideArray(objValue: any, srcValue: any) {
        if (objValue && Array.isArray(objValue)) {
          return srcValue;
        }
      }
      mergewith(environment, config, overrideArray);
      
      // merchantMode is used in lower case by admin-portal
      if (environment.merchantMode) {
        environment.merchantMode = environment.merchantMode.toLowerCase();
      }
    })
    );
  }

  /**
   * Fetch config + override current config (environment variable) with remote value.
   * @returns
   */
  refreshConfiguration(): Observable<any> {
    function overrideArray(objValue: any, srcValue: any) {
      if (objValue && Array.isArray(objValue)) {
        return srcValue;
      }
    }

    return this.fetchConfiguration().pipe(tap(config => {
      merge(environment.admin.sips, config.admin.sips);
      mergewith(environment.admin.multitenant, config.admin.multitenant, overrideArray);
      mergewith(environment.admin.application, config.admin.application, overrideArray);
      mergewith(environment.admin.users, config.admin.users, overrideArray);
      mergewith(environment.admin.customerRequest, config.admin.customerRequest, overrideArray);
      mergewith(environment.admin.tariffication, config.admin.tariffication, overrideArray);
      mergewith(environment.admin.devices, config.admin.devices, overrideArray);
      mergewith(environment.admin.accessMediaReader, config.admin.accessMediaReader, overrideArray);
      mergewith(environment.admin.companies, config.admin.companies, overrideArray);
      mergewith(environment.admin.api, config.admin.api, overrideArray);
      merge(environment.ogone, config.ogone);

      environment.admin.keycloak = config.admin.keycloak;
      environment.admin.kibana = config.admin.kibana;
      if (config.merchantMode) {
        environment.merchantMode = config.merchantMode.toLowerCase();
      }
      if (config.admin.topology) {
        environment.admin.topology = config.admin.topology;
      }
    }));
  }

  /**
   * Init language for web app
   * Set lang for translate service
   */
  async initLanguage(): Promise<void> {
    this.translate.addLangs(environment.admin.application.languages);
    const defaultLang = environment.admin.application.languages[0] || 'fr';

    this.translate.setDefaultLang(defaultLang);
    try {
      const userTokenParsed: CustomKeycloakTokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;
      // In case there is no dropdown to select language
      if (userTokenParsed.locale && environment.admin.application.languages.includes(userTokenParsed.locale)) {
        this.translate.use(userTokenParsed.locale);
        this.document.documentElement.lang = userTokenParsed.locale;
      } else {
        this.translate.use(defaultLang);
        this.document.documentElement.lang = defaultLang;
      }
    } catch (_err) {
      this.translate.use(defaultLang);
      this.document.documentElement.lang = defaultLang;
    }
  }


  /**
   * Reload translations
   */
  async reloadAndUseTranslation(language: string): Promise<any> {
    this.translate.reloadLang(language).subscribe(() => {
      this.httpClient.get(`./assets/i18n/custom/${ language }.json?cacheBuster=${new Date().getTime()}`).subscribe((translations) => {
        this.translate.setTranslation(language, translations, true)
        this.translate.use(language);
        this.document.documentElement.lang = language;
      });
    });
  }

    /**
   * Reload translations
   */
  async reloadTranslation(language: string): Promise<any> {
    this.translate.reloadLang(language).subscribe(() => {
      this.httpClient.get(`./assets/i18n/custom/${ language }.json?cacheBuster=${new Date().getTime()}`).subscribe((translations) => {
        this.translate.setTranslation(language, translations, true)
      });
    });
  }

  /**
   * Add custom translations
   */
  async addCustomTranslation(): Promise<any> {
    environment.admin.application.languages.forEach(async lang => {
      await this.httpClient.get(`./assets/i18n/custom/${ lang }.json?cacheBuster=${new Date().getTime()}`).toPromise();
      await this.reloadTranslation(lang);
    })
  }

}

declare interface CustomKeycloakTokenParsed  extends Keycloak.KeycloakTokenParsed {
  locale?: string;
}
